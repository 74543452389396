import { b as DATERANGES } from "./index8.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list"
  }, [_c("a-row", {
    staticClass: "list__title pt-2 pb-4"
  }, [_c("a-col", {
    staticClass: "background--white",
    attrs: {
      "span": 24
    }
  }, [_c("h2", {
    staticClass: "mb-0 neutral-10--text"
  }, [_vm._v("Export Orders Status")])])], 1), _c("div", {
    staticClass: "list__table p-5"
  }, [_c("div", {
    staticClass: "list__basic-filters d-flex justify-content-between mb-3"
  }, [_c("div", {
    staticClass: "d-flex justtify-content-between align-items-end"
  }, [_c("div", {
    staticClass: "filter mr-4"
  }, [_c("select-filter", {
    key: "Country",
    attrs: {
      "data-source": _vm.countries,
      "value": _vm.valueCountry,
      "source": "countryCode",
      "source-label": "name",
      "placeholder": "Select Country"
    },
    on: {
      "change": function($event) {
        return _vm.onFilterChange("CountryCode", $event);
      }
    }
  })], 1), _c("div", {
    staticClass: "filter mr-4"
  }, [_c("select-filter", {
    key: "DateRanges",
    attrs: {
      "data-source": _vm.dateRanges,
      "source": "value",
      "source-label": "name",
      "value": _vm.valueDateRange
    },
    on: {
      "change": function($event) {
        return _vm.onFilterChange("DateRanges", $event);
      }
    }
  })], 1), _c("div", {
    staticClass: "filter mr-4"
  }, [_c("select-filter-v-2", {
    key: "Client",
    attrs: {
      "reference": "export-order.common.clients",
      "source": "code",
      "source-label": "name",
      "has-option-all": true,
      "value": _vm.valueClient
    },
    on: {
      "change": function($event) {
        return _vm.onFilterChange("Client", $event);
      }
    }
  })], 1), _c("a-button", {
    attrs: {
      "icon": "close-circle"
    },
    on: {
      "click": _vm.onClearFiltersClick
    }
  }, [_vm._v("Clear filters")])], 1), _c("div", {
    staticClass: "d-flex align-items-center filter-container"
  }, [_c("a-badge", {
    attrs: {
      "dot": _vm.isAdvancedFiltered
    }
  }, [_c("a-button", {
    staticClass: "ml-2",
    on: {
      "click": _vm.onAdvancedSearchClick
    }
  }, [_vm._v("Advanced Search")])], 1)], 1)]), _c("a-table", {
    attrs: {
      "bordered": "",
      "loading": _vm.isLoading,
      "columns": _vm.columnsActive,
      "data-source": _vm.resourceList,
      "pagination": false,
      "row-key": function(record, index2) {
        return record.order + "-" + index2;
      },
      "indent-size": -15,
      "scroll": {
        x: 2e3,
        y: 500
      }
    },
    on: {
      "change": _vm.onTableChange
    }
  })], 1)], 1);
};
var staticRenderFns$1 = [];
var ListExportOrdersStatus_vue_vue_type_style_index_0_scoped_true_lang = "";
const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0
});
const __vue2_script$1 = {
  name: "ListExportOrdersStatus",
  inject: ["apiUrl", "crud", "resourceName", "can"],
  data() {
    const h = this.$createElement;
    const columns = [
      {
        title: "Freight Terms",
        dataIndex: "freightTerms",
        key: "freightTerms",
        sorter: true,
        width: 150,
        customRender: (value, row) => {
          if (row.freightTerms === "Grand Total") {
            return {
              children: value,
              attrs: {
                colSpan: 11
              }
            };
          }
          return {
            children: value
          };
        }
      },
      {
        title: "Country Code",
        dataIndex: "countryCode",
        key: "countryCode",
        sorter: true,
        width: 150,
        customRender: (value, row) => {
          if (row.freightTerms === "Grand Total") {
            return {
              attrs: {
                colSpan: 0
              }
            };
          }
          return {
            children: value
          };
        }
      },
      {
        title: "Financial Week",
        dataIndex: "finacialWeek",
        key: "finacialWeek",
        sorter: true,
        width: 150,
        customRender: (value, row) => {
          if (row.freightTerms === "Grand Total") {
            return {
              attrs: {
                colSpan: 0
              }
            };
          }
          return {
            children: value ? this.$moment(value).format("DD/MM/YYYY") : ""
          };
        }
      },
      {
        title: "Cust Name",
        dataIndex: "custName",
        key: "custName",
        sorter: true,
        width: 250,
        customRender: (value, row) => {
          if (row.freightTerms === "Grand Total") {
            return {
              attrs: {
                colSpan: 0
              }
            };
          }
          return {
            children: value
          };
        }
      },
      {
        title: "Client",
        dataIndex: "client",
        key: "client",
        sorter: true,
        width: 100,
        customRender: (value, row) => {
          if (row.freightTerms === "Grand Total") {
            return {
              attrs: {
                colSpan: 0
              }
            };
          }
          return {
            children: value
          };
        }
      },
      {
        title: "Order",
        dataIndex: "order",
        key: "order",
        sorter: true,
        width: 100,
        customRender: (value, row) => {
          if (row.freightTerms === "Grand Total") {
            return {
              attrs: {
                colSpan: 0
              }
            };
          }
          return {
            children: value
          };
        }
      },
      {
        title: "Cust PO",
        dataIndex: "custPO",
        key: "custPO",
        sorter: true,
        width: 100,
        customRender: (value, row) => {
          if (row.freightTerms === "Grand Total") {
            return {
              attrs: {
                colSpan: 0
              }
            };
          }
          return {
            children: value
          };
        }
      },
      {
        title: "Orig ETD",
        dataIndex: "origETD",
        key: "origETD",
        sorter: true,
        width: 100,
        customRender: (value, row) => {
          if (row.freightTerms === "Grand Total") {
            return {
              attrs: {
                colSpan: 0
              }
            };
          }
          return {
            children: value ? this.$moment(value).format("DD/MM/YYYY") : ""
          };
        }
      },
      {
        title: "ETD Date",
        dataIndex: "etdDate",
        key: "etdDate",
        sorter: true,
        width: 100,
        customRender: (value, row) => {
          if (row.freightTerms === "Grand Total") {
            return {
              attrs: {
                colSpan: 0
              }
            };
          }
          return {
            children: value ? this.$moment(value).format("DD/MM/YYYY") : ""
          };
        }
      },
      {
        title: "Notes",
        dataIndex: "notes",
        key: "notes",
        width: 200,
        ellipsis: true,
        customRender: (value, row) => {
          if (row.freightTerms === "Grand Total") {
            return {
              attrs: {
                colSpan: 0
              }
            };
          }
          return h("a-popover", {
            props: {
              content: value,
              title: "Notes",
              overlayStyle: { width: "250px" },
              placement: "bottomLeft"
            }
          }, [h("span", value)]);
        }
      },
      {
        title: "Inv/Ship Date",
        dataIndex: "invShipDate",
        key: "invShipDate",
        sorter: true,
        customRender: (value, row) => {
          if (row.freightTerms === "Grand Total") {
            return {
              attrs: {
                colSpan: 0
              }
            };
          }
          return {
            children: value ? this.$moment(value).format("DD/MM/YYYY") : ""
          };
        }
      },
      {
        title: "Sum of Qty",
        dataIndex: "sumOfQty",
        key: "SumOfQty",
        width: 150,
        fixed: "right",
        sorter: true
      },
      {
        title: "VALUE (AUD)",
        dataIndex: "valueAUD",
        key: "valueAUD",
        width: 150,
        fixed: "right",
        sorter: true,
        hidden: false,
        customRender: (value) => {
          return {
            children: this.formatCurrency(value)
          };
        }
      },
      {
        title: "VALUE (NZD)",
        dataIndex: "valueNZD",
        key: "valueNZD",
        width: 150,
        fixed: "right",
        sorter: true,
        hidden: true,
        customRender: (value) => {
          return {
            children: this.formatCurrency(value)
          };
        }
      },
      {
        title: "VALUE (USD)",
        dataIndex: "valueUSD",
        key: "valueUSD",
        width: 150,
        fixed: "right",
        sorter: true,
        customRender: (value) => {
          return {
            children: this.formatCurrency(value)
          };
        }
      }
    ];
    return {
      columns,
      countries: [],
      dateRanges: DATERANGES
    };
  },
  computed: {
    filterCountrys() {
      return this.crud.getFilter("CountryCode");
    },
    valueCountry() {
      if (!this.filterCountrys) {
        return { operator: "Eq", value: "AU" };
      }
      return this.filterCountrys;
    },
    filterDateRanges() {
      return this.crud.getQueryString("isCurrentMonth");
    },
    valueDateRange() {
      if (this.filterDateRanges.value == "false") {
        return { operator: "Eq", value: "NextMonth" };
      }
      return { operator: "Eq", value: "CurrentMonth" };
    },
    filterClients() {
      return this.crud.getFilter("Client");
    },
    valueClient() {
      if (!this.filterClients) {
        return { operator: "Eq", value: null };
      }
      return this.filterClients;
    },
    resourceList() {
      return this.crud.getList();
    },
    isLoading() {
      return this.$store.state[this.resourceName].isFetchingList;
    },
    columnsActive() {
      return this.columns.filter((item) => !item.hidden);
    },
    isAdvancedFiltered() {
      return this.crud.getIsAdvancedFiltered();
    }
  },
  created() {
    this.getCountries();
    this.getDefaultValues();
  },
  methods: {
    formatCurrency(value) {
      return usdFormatter.format(value);
    },
    handleColumns(value) {
      this.columns.forEach((item) => {
        switch (item.dataIndex) {
          case "valueAUD":
            item.hidden = !value;
            break;
          case "valueNZD":
            item.hidden = value;
            break;
        }
      });
    },
    getDefaultValues() {
      this.crud.deleteFilter("IsInactive");
      this.crud.setFilter("CountryCode", {
        operator: "Contains",
        value: "AU"
      });
      this.crud.setFilter("Client", {
        operator: "Contains",
        value: null
      });
      this.crud.setQueryString("isCurrentMonth", true);
      this.handleColumns(true);
      this.crud.fetchList();
    },
    getCountries() {
      this.axios.get(`${this.apiUrl}/export-order/common/countries`).then((res) => {
        let { data: countries } = res;
        this.countries = [...countries];
      });
    },
    onFilterChange(type, data) {
      let isCurrentMonth;
      const { value } = data;
      this.crud.deleteFilter("IsInactive");
      switch (type) {
        case "DateRanges":
          isCurrentMonth = value === "CurrentMonth";
          this.crud.setQueryString("isCurrentMonth", isCurrentMonth);
          break;
        case "CountryCode":
          this.handleColumns(value == "AU");
          this.crud.setFilter("CountryCode", {
            operator: "Contains",
            value
          });
          break;
        case "Client":
          this.crud.setFilter("Client", {
            operator: "Contains",
            value
          });
          break;
      }
      this.crud.fetchList();
    },
    onAdvancedSearchClick() {
      this.$router.push("/export-orders/export-orders-status/advanced-search");
    },
    onClearFiltersClick() {
      this.crud.clearFilters();
      this.crud.deleteQueryStrings();
      this.crud.setIsAdvancedFiltered(false);
      this.getDefaultValues();
    },
    onTableChange(pagination, filters, sorter) {
      const { columnKey, order } = sorter;
      this.crud.setSorter({ columnKey, order });
      this.crud.fetchList();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "481b0e0e", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListExportOrdersStatus = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-export-orders-status"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "export-order.common.clients",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "export-order.export-order-statuses",
      "api-url": _vm.apiUrl
    }
  }, [_c("list-export-orders-status")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListExportOrdersStatus
  },
  data() {
    return {
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Export Orders Status",
          path: "/export-orders/export-orders-status"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
